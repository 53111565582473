import { isArray, pickBy, random, times } from "lodash";
import { UnitModel } from "models/UnitModel";
import { IMAGE_PLACEHOLDER } from "./constants";

export class Utils {
  static getRandomString = (length = 20): string =>
    times(length, () => random(35).toString(36)).join("");

  static sanitizeObject = (data: any) => {
    return pickBy(data, (v: any) =>
      isArray(v) ? !!v.length : v !== null && v !== undefined && v !== ""
    );
  };

  static getUniqueId = (): string =>
    (
      Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    ).toUpperCase();

  static removeObjectEmptyValues = (obj) => {
    return Object.keys(obj)
      .filter((k) => obj[k] != null && obj[k] !== "")
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  };

  private static objectToGetParams = (object: {
    [key: string]: string | number | undefined | null;
  }) => {
    const params = Object.entries(object)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      );

    return params.length > 0 ? `?${params.join("&")}` : "";
  };

  static getPinterestShareUrl = (
    description: string,
    url: string,
    media: string
  ) => {
    return (
      "https://pinterest.com/pin/create/button/" +
      this.objectToGetParams({
        url,
        media,
        description,
      })
    );
  };

  static getFacebookShareUrl = (title: string, url: string) => {
    return (
      "https://www.facebook.com/sharer/sharer.php" +
      this.objectToGetParams({
        quote: title,
        u: url,
      })
    );
  };

  static getTwitterShareUrl = (
    title: string,
    url: string,
    imageUrl: string
  ) => {
    return (
      "https://twitter.com/intent/tweet" +
      this.objectToGetParams({
        text: title,
        url: url,
        via: imageUrl,
      })
    );
  };

  static getGoogleMapUrl = (lat: number, long: number) => {
    return `https://maps.google.com?q=${lat},${long}`;
  };
}

export const getImageUrl = (unit: UnitModel) => {
  if (unit.unit_images.items.length > 0) {
    return unit.unit_images.items[0].url;
  } else if (unit.building.item.building_images.items.length > 0) {
    return unit.building.item.building_images.items[0].url;
  }

  return IMAGE_PLACEHOLDER;
};

export const getBedroomBathroom = (unit: UnitModel) => {
  let bedBathCount = "- / -";
  if (unit?.bedrooms === 0) {
    bedBathCount = "Studio";
  } else if (unit?.bedrooms > 0) {
    bedBathCount = `${unit.bedrooms} bd`;
  }

  if (unit?.bathrooms) {
    bedBathCount = `${bedBathCount} / ${unit.bathrooms} ba`;
  }

  return bedBathCount;
};

export const getUnitTitle = (unit: UnitModel): string => {
  return `${unit.address_line_1} - ${unit.name}, ${unit.city_name}, ${unit.state_code} ${unit.zip_code}`;
};

export const getCompleteLogoUrl = (logo: string): string => {
  return `https://assets.mymatrixrent.com/${logo}`;
};
